.itours-icon{
  background-image: url("../Images/design/icons.svg");

  //as IE dosnt support svg background:
  /*     @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          background-image: url("../image/icons.png");
      } */
  background-size: 1000% 300%;
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
  &.icon-small{
    width:20px;
    height:20px;
  }
  &.icon-xsmall{
    width:15px;
    height:15px;
  }
  &.icon-large{
    width:45px;
    height:45px;
  }
  &.icon-xlarge{
    width:70px;
    height:70px;
  }

  &.home-icon{
    background-position: 0 0;
  }
  &.hotel-icon{
    background-position: 22.4% 0;
  }
  &.hotel-orange-icon{
    background-position: 22.4% 100%;
  }
  &.flight-icon{
    background-position: 11.3% 0;
  }
  &.to-right-flight-icon{
    background-position: 11.3% 100%;
  }
  &.payment-icon{
    background-position: 55.5% 0;
  }
  &.user-icon{
    background-position: 66.7% 0;
  }
  &.list-icon{
    background-position: 44.8% 0;
  }
  &.persent-icon{
    background-position: 33.7% 0;
  }
  &.settings-icon{
    background-position: 77.7% 0;
  }
  &.chat-icon{
    background-position: 33.7% 50%;
  }
  &.telegram-icon{
    background-position: 11.3% 50%;
  }
  &.whatsapp-icon{
    background-position: 0 50%;
  }
  &.chat-icon{
    background-position: 33.7% 50%;
  }
  &.email-icon{
    background-position: 22.3% 50%;
  }
  &.phone-icon{
    background-position: 44.4% 50%;
  }
  &.deposit-icon{
    background-position: 0 100%;
  }
  &.breakfast-icon{
    background-position: 33.7% 100%;
  }
}
