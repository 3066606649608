@import "base";
.galleryContainer{
  width: 100%;
  .float-row.reverse{
    display: flex;
    &>div{
      max-width: 400px;
      max-height: 300px;
    }
  }
  .line{
    display: flex;
    .side{
      width:50%;
    }

  }
  .image{
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease-out;
    height: 200px; 
    border: 4px solid #fff;
    box-shadow: 0 0 3px 0 black;
    border-radius: 2px;

    background-color: #bbb;
  
    &:hover{
      .hover{
        transform: scale(1);
      }
    }
    .hover{
      display: none;
/*       position: relative;
      background-color: rgba(0, 0, 0, 0.52);
      height: 100%;
      text-align: center;
      transform: scale(0);
      transition: 0.2s ease-out;
      @media screen and (max-width: 850px){
        opacity:0;
        transform: scale(1);        
      }      
      span{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        color:white;
        img{
          height: 60px;
        }
      } */
    }
/*     &.imgBig{
      height: 300px;
    }
    &.imgMedium{
      height: 200px;
    }
    &.imgSmall{
      height: 150px;
    } */
  }
}
#lightboxBackdrop {
  direction: ltr;
  font-family: opensans;
  button {
    background: transparent;
    min-width: 0;
    &:hover{
      opacity: .4;
    }
  }
}

.gallery-icon {
  width: 60px;
  height: 60px;
  display: inline-block;
  background-position: -540px 0;
}


//new style:
.hotel-gallery{
  & > div {
    height: 350px;
  }
  .image {
    height: 100%;
    background-size: cover;
    background-position: center;
    border: 1px solid #fff;
    position: relative;
    .hover {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
    }
  }
}
.gallery-row {
  display: flex;
  & > div {
    flex: 1 1;
    &.large-item{
      flex: 4 4;
    }
  }
}
.gallery-column {
  display: flex;
  flex-direction: column;
  & > div {
    flex: 1 1;
  }
}
