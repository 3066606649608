@import "base";
.autocompleteFeild{
  .autocompleteBox{
    position: relative;
    .icon{
      position: absolute;
      right: 8px;
      top:10px;
      &.anticon-loading {
        background: #fff;
        padding: 5px;
        top: 50%;
        margin-top: -12px;
        z-index: 10;
      }
    }
  }
  input{
    border:0;
    height: 30px;
    border-radius: 4px;
    width: 100%;
  }
  .invalid-input{
    border:1px solid #f5222d;
  }
  .invalid-label{
    display: block;
    color:#f5222d;
    padding:4px 0;
  }
  .autocompleteText{
    position: relative;
    .suggestList{
      position: absolute;
      background-color: white;
      box-shadow: 0 0 4px grey;
      min-width: 300px;
      top: 38px;
      border-radius: 4px;
      z-index: 9;
      -webkit-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
      @media screen and (max-width: 720px){
        min-width: 100%;
      }
      &>ul{
        max-height: 310px;
      }
      ul{
        width: 100%;
        color:gray;
        margin: 0;
        line-height: 24px;
        list-style: none;
        padding: 0;
        overflow-y: auto;
        li{
          cursor: pointer;
          transition: all 0.4s ease-out;
        }
      }
    }
  }
}

.autocomplete-list{
  .list-link {
    font-size: 14px;
    position: relative;
    padding: 5px 5px 5px 25px;
    border-bottom: 1px solid #eee;
    transition: all .1s linear;
    &.subset {
      padding-left: 40px;
      font-size: 13px;
      .subset-icon {
        width: 12px;
        left: 25px;
        top: 10px;
      }
      .hotel-icon {
        width: 15px ;
      }

    }
    &:hover{
      background-color: rgb(224, 224, 224);
    }
    .location {
      font-size: 12px;
    }
  }
  .list-link img {
    width:17px;
    position: absolute;
    left: 5px;
    top: 9px;
  }
}

