.loginScreen{
  .logo{
    text-align: center;
    img{
      height: 120px;
      padding: 16px;
    }

  }
  height: 100vh;
  background-color: white;
  #login,#recover{
    width:100%;
    padding: 15px;
    @media screen and (min-width:768px){
      box-shadow: 0 0 4px gray;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      max-width: 400px;
    }
  }

}
.min-width-150 {
  min-width: 150px;
}
.ant-checkbox-wrapper {
  line-height: 20px;
}