@import "base";
.hotel-result-item {
    background: #ffffff;
    @media screen and (min-width: 768px){
      display: flex;
    }
    margin-bottom: 20px;
    overflow: hidden;
    min-height: 170px;
    border: 1px solid #ddd;
    .hotel-results-image {
      flex: 0 0 210px;
      &.main-hotel-image {
        background-color: #003057;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @media screen and (max-width: 767px){
          width: 100%;
          display: block;
          height: 40vw;
        }
      }
      &.default-hotel-image{
        background-color: #f1f1f1;
        background-size: 60px;
        background-position: center;
        background-repeat: no-repeat;
        @media screen and (max-width: 767px){
          display: none;
        }
      }
      @media screen and (max-width: 991px){
        flex: 0 0 90px;
      }
    }
    .hotel-result-main{
      width:100%;
      display: flex;
      @media screen and (max-width: 720px){
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .hotel-results-content{
      padding: 15px;
      width: 100%;
    }

  }

//gallery
.image_slider{
  .imageSlider{
    height: 200px;
    background-size: cover;
  }
}
.hotel-result-item{

  .hotel-name {
    font-size: 18px;
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
    color: $blue;
  }
  .rating{
    vertical-align: middle;
    display: inline-block;
  }
}
.search-btn-container{
  margin-top: 15px;
}
.select-room-item{

  .room-item {
    margin-top: 20px;
    .room-title {
      font-size: 18px;
      line-height: 27px;
      padding: 18px 15px;
      border-bottom: 1px solid rgb(211,211,211);
      font-size: 18px;
      b{
        font-weight: 600;
      }
    }
    .room-detail {
      .price{
        font-weight: 600;
      }
      .room-table-title {
        font-weight: 600;
        font-size: 12px;
      }
        .detail-table-cell{
          padding-bottom: 5px;
          .button{
            font-size:14px;
          }
        }
    }
    .detail-table {
      margin-bottom:15px;
    }
    @media screen and (max-width: 500px){
      border-right: 0 none;
      border-left: 0 none;
      .room-title {
        padding: 10px;
        b{
          font-size: 16px;
          font-weight: normal;
          text-transform: uppercase;
        }
      }
      .button {
        width: 100%;
        margin-top: 5px;
      }
    }
    .detail-table-tbody .guest-dark{
      position: relative;
      top:-2px;
    }
    @media screen and (min-width: 500px){
      .cancelationContainer {
        background-color: #fff;
        border: 1px solid rgb(211,211,211);
        border-top: 0 none;
      }
      .detail-table {
        display: table;
        width: 100%;
        border-collapse: collapse;
        margin-bottom:0;
      }
      .detail-table-tbody {
        display: table-row-group;
        width: 100%;

        .detail-table-row {
          display: table-row;
          &.bordered-row .detail-table-cell{
            border: 1px solid rgb(224,224,224);
            padding: 10px 10px;
            &:last-child{
              width:15%;
            }
            &:nth-child(2){
              width:20%;
            }
            &:nth-child(3){
              width:20%;
            }
            .button {
              min-width: 148px;
            }
          }
          .detail-table-cell {
            display: table-cell;
          }
        }

      }
    }

  }
  .selected-room,.selectedRoom {
    padding:10px  15px;
    border: 1px solid #ddd;
    background-color: #fff;
  }
}
.cancelationContainer {
  margin-top:15px;
}
.normal-title {
  font-size: 16px;
  color: #444;
}
.hotel-details-box{
  b{
    font-size: 16px;
    font-weight: normal;
    color: #444;
  }
}

.hotel-name-star {

  .normal-title{
    margin-bottom: 0;
  }
}
.hotel-img-titles {
  display: flex;
  margin-bottom: 15px;

}
.hotel-image {
  flex: 0 0 100px;
  min-height: 90px;
  margin-right: 5px;
  background-size: cover;
  &.default-hotel-image{
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.room-item-inner {
  div:first-child{
    padding-bottom: 8px;
  }
  .breakfast{
    margin-left: 6px;
    position: relative;
    border-radius: 35px;
    border:1px solid $green;
    padding: 4px 10px;
    color: $green;
    padding-left:40px ;
    .itours-icon{
      top:50%;
      transform:translateY(-50%);
      left: 4px;
      position: absolute;
      margin: 0 4px;
      height: 28px;
      width:28px;
    }
  }
  .default{
    margin-left: 6px;
    border-radius: 35px;
    border:1px solid $grayMenuHov;
    padding: 4px 10px;
    color: $grayMenuHov;
  }
  @media screen and (min-width: 768px){
    display: flex;
    justify-content: space-between;
  }
  .item-footer {
    flex: 0 0 100px;
    align-self: flex-end;
  }
  &:not(:last-child){
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

//hotel-v2 styles:
.room-image-holder {
  background: #f1f1f1;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.room-image {
  margin: auto;
  width: 120px;
}

.hotel-room-table {
  width: 100%;
  th {
    font-size: 12px;
    font-weight: 600;
    color: rgb(58, 58, 58);
    padding-bottom: 10px;
  }
  td{
    border: 1px solid rgb(224, 224, 224);
    padding: 15px;
    vertical-align: top;
  }
  @media (min-width:576px){
    .mobile-label{
      display: none;
    }
  }
  @media (max-width:575px){
    .mobile-label {
      color: #bbb;
      font-weight: normal;
      font-size: 13px;
    }
    display: block;
    thead {
      display: none;
    }
    tbody,tr {
      display: block;
      &:not(:last-child){
        margin-bottom: 30px;
      }
      td {
        display: block;
        border: 0 none !important;
        padding: 0;
        margin-bottom: 15px;
        & >div:nth-child(2){
          padding-left: 25px;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  
}

.select-room-wrapper{
  .itours-modal .modal-inner{
    @media (min-width:768px) {
      max-width: 600px;
    }
  }
  .new-price {
    font-size: 18px;
    font-weight: 600;
    color: rgb(58, 58, 58);
  }

  .rooms-capacity {
    font-size: 12px;
    font-weight: normal;
    color: rgb(119, 119, 119);
  }
}
.ant-card.multiple-rooms .ant-card-body {
  background: #f9f8fa;
  .option-item {
    background: #fff;
    border: 1px solid rgb(224, 224, 224);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    &:last-child{
      margin-bottom: 0;
    }
    .ant-col{
      padding: 15px;
      &:not(:last-child){
        border-right: 1px solid rgb(224, 224, 224);
      }
    }
  }
}
.multy-rooms-titles {
  
  .items{
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 15px;
  }
  .room-image {
    width: 50px;
    margin-right: 10px;
  }
  strong {
    margin-bottom: 10px;
    display: block;
    color: #333;
  }
}
