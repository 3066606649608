//@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

@font-face {
font-family: 'Roboto';
src: url('../Fonts/Roboto-Thin.ttf') format('truetype');
font-weight: 100;
font-style: normal;
font-display: swap;
}
@font-face {
font-family: 'Roboto';
src: url('../Fonts/Roboto-Light.ttf') format('truetype');
font-weight: 300;
font-style: normal;
font-display: swap;
}
    
@font-face {
font-family: 'Roboto';
src: url('../Fonts/Roboto-Medium.ttf') format('truetype');
font-weight: 400;
font-style: normal;
font-display: swap;
}
@font-face {
font-family: 'Roboto';
src: url('../Fonts/Roboto-Medium.ttf') format('truetype');
font-weight: 500;
font-style: normal;
font-display: swap;
}
@font-face {
font-family: 'Roboto';
src: url('../Fonts/Roboto-Bold.ttf') format('truetype');
font-weight: 800;
font-style: normal;
font-display: swap;
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: url('../Fonts/opensans/open-sans-v17-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('../Fonts/opensans/open-sans-v17-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Fonts/opensans/open-sans-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Fonts/opensans/open-sans-v17-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../Fonts/opensans/open-sans-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Fonts/opensans/open-sans-v17-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../Fonts/opensans/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../Fonts/opensans/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Fonts/opensans/open-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Fonts/opensans/open-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../Fonts/opensans/open-sans-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Fonts/opensans/open-sans-v17-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

//var
$navbarHeight:60px;
//colors
$grayMenu:#dad6d6;
$grayMenuHov:#b9b9b9;
$blue:#1890ff;
$blueHover: #0077e6;
$darkblue: #003580;
$red:#f5222d;
$green: #308c6a;
$yellow :#ff9800;
$violet: #673AB7;
$grey: #aaa;
$orange: orange;

