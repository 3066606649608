@import "base";
#filterButton{
    display: none;
    position: fixed;
    z-index: 999;
    bottom: 30px;
    left:50%;
    transform: translateX(-50%);
    box-shadow: 0 0 4px gray;
    @media screen and(max-width:700px){
        display: block;
    }
}
#filterSide{
    .closeFilter{
        display: none;
    }
    @media screen and(max-width:700px){
        &.close{
            display: none;
        }
        .closeFilter{
            display: block;
            margin: 8px auto;
        }
        background-color: white;
        position: fixed;
        width: 100vw;
        height: 100%;
        top:0;
        left: 0;
        z-index: 99999;
        padding-left: 0 !important;
        padding-right: 0 !important;
        overflow-y: auto;
        .ant-radio-group{
            width: 100%;
            .filter-reserves{
                width: 100%;
                border-radius: 2px;
                background-color: $blue;
                border:0;
                margin-bottom: 2px;
                text-align: center;
                color:white;
                font-weight: 400;
            }
        }
    }
}
#reserveList{
    .ant-tag{
        white-space: normal;
    }
}
.filter-container, .list-container {
    padding: 0 15px!important;
}
.filter-container {
    .infoCardSearch-container, .infoCardBooking-container, .infoCardReserveStatus-container {
        margin: 10px 0px;
        box-shadow: 0px 0px 8px -3px #8c8c8c;
        border-radius: 3px;
        padding: 15px;
    }
    .title {
        margin: 25px 0;
    }
    input {
        margin: 8px 0px;
    }
    button {
        margin: 15px 10px 15px 0;
    }
    span.ant-calendar-picker {
        display: block;
    }
    label.ant-checkbox-wrapper {
        display: block;
        margin: 5px 0;
    }
}

.infoCard-container {
    position: relative;
    @media screen and(min-width:768px){
        .ant-card{
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .infoCard-date {
        p {
            margin: 0px;
        }
        p:nth-child(even) {
            color: #ababab;
        }
        .reserve-number {
            color: #2874ff;
        }
    }
    .infoCard-price {
        p {
            margin: 0;
            &.greenPrice {
                color: #5ea71c;
                margin-bottom: 7px;
            }
        }
    }
    .bold-price .price {
        font-weight: bold;
        font-size: 16px;
        color: #222;
    }
    .infoCard-button {
        .paymentStatus {
            span {
                font-size: 11px;
                padding: 3px 5px;    
                background-color: #f5f5f5;
                color: #2a9228;
            }
        }
        button {
            margin-right: 5px;
        }
    }
}
//flight details
.flightDetails{
    .hotelName{
        font-size: 24px;
        display: flex;
        line-height: 20px;
        .rateContainer{
            padding: 0 6px;
        }
        padding: 8px 0;
    }
    .contact{
        padding-top: 12px;
    }
    button{
        height: 55px;
        margin: 4px 0;
    }
    .ReserveInformation{
        .title{
            font-size: 20px;
            margin-top: 20px;
            color:rgba(0, 0, 0, 0.85);
            margin-bottom: 0.5em;
        }

    }

}
//Transaction
.Transaction{
    padding: 12px;
    .title{
        font-size: 20px;
        margin-top: 20px;
        color:rgba(0, 0, 0, 0.85);
        margin-bottom: 0.5em;
    }
}
//for modal
.footerPart{
    display: flex;
    button{
        min-width: 120px;
        margin-right: 4px;
    }
}
.type-icon {
    width: 100%;
    max-width: 35px;
}
.ReserveInformation td {
    border: 1px solid #ddd;
}
label.filter-reserves.ant-radio-button-wrapper {
    padding: 0 7px;
}
.reserves-page .ant-card-body{
    padding: 15px;
    .item-title {
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 1;
        a{
            color: inherit;
        }
    }
}

.peyment-status-color{
    &.Issued{
        color:#52c41a;
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #52c41a;
            border-color: #52c41a;
        }
    }
    &.Refunded{
        color: #722ed1;
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #722ed1;
            border-color: #722ed1;
        }
    }
    &.PaymentSuccessful{
        color: #faad14;
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #faad14;
            border-color: #faad14;
        }
    }
    &.REGISTERED{
        color: #722ed1;
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #722ed1;
            border-color: #722ed1;
        }
    }
    &.WEB_SERVICE_UNSUCCESSFUL{
        color: #f5222d;
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #f5222d;
            border-color: #f5222d;
        }
    }
    &.UNAVAILABLE{
        color: #f5222d;
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #f5222d;
            border-color: #f5222d;
        }
    }
    &.Canceled{
        color: rgba(0, 0, 0, 0.65);
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: rgba(0, 0, 0, 0.65);
            border-color: rgba(0, 0, 0, 0.65);
        }
    }
    &.WEB_SERVICE_CANCEL{
        color: #faad14;
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #faad14;
            border-color: #faad14;
        }
    }
    &.UNDEFINED{
        color: #f5222d;
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #f5222d;
            border-color: #f5222d;
        }
    }
    &.Pending{
        color: #1890ff;
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #1890ff;
            border-color: #1890ff;
        }
    }
    &.PRICE_CHANGE{
        color: #f5222d;
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #f5222d;
            border-color: #f5222d;
        }
    }
    &.Voided {
        color: rgba(0, 0, 0, 0.65);
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: rgba(0, 0, 0, 0.65);
            border-color: rgba(0, 0, 0, 0.65);
        }
    }
    &.Failed{
        color: #f5222d;
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #f5222d;
            border-color: #f5222d;
        }
    }
    .ant-checkbox-wrapper{
        color: inherit;
    }
}


.status-price-block{
    display: flex;
    padding-left: 20px;
    justify-content: flex-end;
    .price {
        font-weight: bold;
        font-size: 15px;
        .value {
            font-size: 20px;
        }
    }
    .profit-wrapper{
        font-size: 13px;
        .price {
            font-weight: bold;
            font-size: 13px;
            .value {
                font-size: 15px;
            }
        }
    }
    .anticon {
        font-size: 18px;
        vertical-align: middle;
    }
}
.infoCard-container .reserve-button-group .button {
    min-width: 0;
    line-height: 16px;
    font-size: 14px;
    border: 1px solid transparent;
    border-radius: 2px;
    text-decoration: none;
    padding: 8px 10px;
    margin-left: 5px;
    margin-bottom: 5px;
    outline: 0 none;
    cursor: pointer;
    transition: all .2s linear;
    &.invoice-btn {
        color: #333;
        background-color: #f1f1f1;
        &:hover{
            border-color: #ccc;
        }
    }
    &.voucher-ticket-btn {
        color: #333;
        background-color: #fef7e5;
        &:hover{
            border-color: #e6dbc1;
        }
    }
    &.details-btn {
        color: #333;
        background-color: #d7f8c1;
        &:hover{
            border-color: #aed496;
        }
    }
    &.ticketing-showPNR-btn {
        color: #333;
        background-color: #f8f0ff;
        &:hover{
            border-color: #d6c5e4;
        }
    }
    &.cancel-btn {
        color: #333;
        background-color: #ffccc8;
        &:hover{
            border-color: #d69d99;
        }
    }
    &.void-btn {
        color: #333;
        background-color: #e6fffb;
        &:hover{
            border-color: #a6d4cd;
        }
    }
    &.payment-btn {
        color: #fff;
        background-color: #1a90fc;
        &:hover{
            background-color: #1e83e0;
        }
    }
}
.align-stretch{
    align-items: stretch;
    .vertical-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}
.reserve-title {
    font-size: 20px;
    a{
        color: inherit;
    }
}
.pull-right-lg {
    float: right !important;
}
@media (min-width:992px) and (max-width:1199px){
    .reserve-item-grid-row{
        .status-price-block-col {
            position: absolute;
            top: 0;
            right: 0;
            width: 260px;
        }
    }
}